.testPage{
  //min-width: 1500px;
  height: 0%;
  .simHeader{
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    font-weight: 700;
    background: #eee;
    color:#0c4da2;
    position: relative;
    //border-bottom:1px solid grey;
    .hscImg{
      position: absolute;
      height: 50px;
      left:8px;
      //margin: 0 8px;
    }
  }
  .fccHeader{
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    font-weight: 700;
    background: whitesmoke;
    color:#0c4da2;
    position: relative;
    //border-bottom:1px solid grey;
    .hscImg{
      position: absolute;
      height: 50px;
      left:8px;
      //margin: 0 8px;
    }
  }
  .testBody{
    height:90%;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

canvas {
  opacity: 0;
  touch-action: none;
  animation: fade-in 2s ease 0.3s forwards;
  animation-delay: 0s;
}
